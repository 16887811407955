import * as React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, Route, Switch } from 'react-router-dom';
import logo from "../assets/logo.svg";
import Contact from "../components/Contact";
import Home from "../components/Home";
import Ordering from "../components/Ordering";
import Pricing from "../components/Pricing";
import "../styles/root.css";

interface RootProps { }
interface RootState { }

class Root extends React.Component<RootProps, RootState> {
    render() {
        return (
            <div>
                <div>
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="navigation">
                        <Navbar.Brand as={Link} to="/"><img src={logo} alt="Kotileipomo Kuorrute" className="logo mr-auto" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="float-right">
                            <Nav>
                                <Nav.Link as={Link} to="/">KOTILEIPOMO KUORRUTE</Nav.Link>
                                <Nav.Link as={Link} to="/tuotteet">TUOTTEET</Nav.Link>
                                <Nav.Link as={Link} to="/tilaaminen">TILAAMINEN</Nav.Link>
                                <Nav.Link as={Link} to="/yhteystiedot">YHTEYSTIEDOT</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <Switch>
                        <Route path='/tuotteet'>
                            <Pricing />
                        </Route>
                        <Route path='/tilaaminen'>
                            <Ordering />
                        </Route>
                        <Route path='/yhteystiedot'>
                            <Contact />
                        </Route>
                        <Route path='/'>
                            <Home />
                        </Route>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default Root;

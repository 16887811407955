import * as React from "react";
import nelli from "../assets/nelli.jpg";
import CakeGallery from "./CakeGallery";

interface HomeProps { }
interface HomeState { }

class Home extends React.Component<HomeProps, HomeState> {
    render() {
        return (
            <div>
                <div className="header">
                    <h1 className="title">Kotileipomo Kuorrute</h1>
                </div>
                <div className="about">
                    <div className="description">
                        <img src={nelli} alt="Nelli Läspä" className="nelli" />
                        <div>
                            <p>
                                Hei! Olen Nelli, vuonna 2018 restonomiksi valmistunut nainen Tampereelta. Kipinän leivontaan olen saanut jo lapsena, ja olen lukioikäisestä lähtien opiskellut erilaisia reseptejä, makuyhdistelmiä ja leivontatekniikoita. Muutaman vuoden ajan olen valmistanut sukulaisille ja ystäville kakkuja erilaisiin tilaisuuksiin, ja vuonna 2019 päätin vihdoin toteuttaa unelmani oman kotileipomon perustamisesta.
                            </p>
                            <br />
                            <p>
                                Tyylini leivonnassa on rustiikkinen, mutta yksityiskohdat huomioon ottava. Rakastan käyttää leivonnasissa marjoja ja hedelmiä, sekä yhdistellä niitä makeampien elementtien kanssa. Kakut ja leivonnaiset valmistan tilauksesta omassa kotonani Tampereen Hallilassa.
                            </p>
                            <br />
                            <p>
                                Löydät sivuiltani tietoa tuotteista, sekä niiden hinnoista. Kakuissa otan erilaisia toiveita mielelläni vastaan ja suunnittelen yhdessä tilaajan kanssa tuotteen haluttua maku- ja värimaailmaa. Minulta voit tilata myös perinteisempiä kotileivonnaisia arjen herkutteluhetkiin tai kahvipöytään vieraita ilahduttamaan.
                            </p>
                            <br />
                            <p>
                                Suunnitellaan yhdessä suussasulavat herkut juuri sinulle!
                            </p>
                        </div>
                    </div>
                </div>
                <CakeGallery />
            </div>
        );
    }
}

export default Home;

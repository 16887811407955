import * as React from "react";
import InstagramEmbed from 'react-instagram-embed';
import "../styles/contact.css";

interface ContactProps { }
interface ContactState { }

class Contact extends React.Component<ContactProps, ContactState> {
    render() {
        return (
            <div className="contact">
                <h1 className="title">Kotileipomo Kuorrute</h1>
                <p>kotileipomokuorrute@gmail.com</p>
                <p>puh. 044 0889587</p>
                <p>Neulaskatu 3, Tampere</p>
                <InstagramEmbed
                    url='https://www.instagram.com/p/CFHg8S9lR8L/'
                    maxWidth={320}
                    hideCaption={true}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => { }}
                    onSuccess={() => { }}
                    onAfterRender={() => { }}
                    onFailure={() => { }}
                    className="instagram"
                />
            </div>
        );
    }
}

export default Contact;

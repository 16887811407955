import * as React from "react";
import "../styles/pricing.css";

interface PricingProps { }
interface PricingState { }

class Pricing extends React.Component<PricingProps, PricingState> {
    render() {
        return (
            <div className="pricing">
                <h1 className="pricing-title">Tuotteet</h1>
                <p className="pricing-about">
                    Tuotteet valmistetaan pääasiassa laktoosittomista raaka-aineista. Muiden erityisruokavalioiden osalta saatavuus on tuotekohtainen. Muista mainita mahdolliset erityisruokavalioihin liittyvät toiveet tilausvaiheessa. Kaikki tuotteet valmistetaan samassa keittiössä, joten esimerkiksi täyttä gluteenittomuutta ei voi taata.
                </p>
                <div className="pricing-cakes">
                    <div className="cakes">
                        <h3>Nakukakut/Valumakakut:</h3>
                        <p>
                            Kakuissa moussetäyte
                            asiakkaan toiveen mukaan,
                            sekä kreemikuorrutus,
                            suklaa- tai kinuskivaluma
                            ja koristelu.
                    </p>
                        <ul>
                            <li>10hlö: 70e</li>
                            <li>15hlö: 80e</li>
                            <li>20hlö: 90e</li>
                            <li>25hlö: 100e</li>
                        </ul>
                    *lisähintaa esim aidoista kukista koristeena kukkien hinnan mukaan
                    </div>
                    <div className="cakes">
                        <h3>Brita-kakku (makuvaihtoehtoja)</h3>
                        <ul>
                            <li>10hlö: 45e</li>
                            <li>15hlö: 55e</li>
                            <li>20hlö: 65e</li>
                        </ul>

                    </div>
                    <div className="cakes">
                        <h3>Juustokakut (hyydytetyt ja paistetut)</h3>
                        <ul>
                            <li>10hlö: 50e</li>
                            <li>15hlö: 60e</li>
                            <li>20hlö: 70e</li>
                        </ul>
                    </div>
                </div>
                <div className="cakes">
                    <h3>Muut leivonnaiset</h3>
                    <ul>
                        <li>Kuppikakut 3,50e/kpl</li>
                        <li>Makeat piirakat (esim Brownie, raparperipiiras, porkkanapiiras, toscapiiras) 20hlö: 50e</li>
                        <li>Cookies (makuvaihtoehtoja) 1e/kpl</li>
                        <li>Macarons (makuvaihtoehtoja) 1,50e/kpl</li>
                    </ul>
                    <p>
                        Voit kysellä myös muun tyyppisten leivonnaisten saatavuudesta!
                    </p>
                </div>
            </div>
        );
    }
}

export default Pricing;

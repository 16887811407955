import * as React from "react";
import cake from "../assets/cake.jpg";
import cake2 from "../assets/IMG_20200902_200201_306.jpg";
import cake3 from "../assets/IMG_20201012_234137_413.jpg";
import cake4 from "../assets/cake4.jpg";
import cake5 from "../assets/IMG_20201031_121833_003.jpg";
import cake6 from "../assets/IMG_20201119_185336_936.jpg";
import cake7 from "../assets/IMG_20200717_214513_784.jpg"
import cake8 from "../assets/IMG_20201128_155426_398.jpg";
import cake9 from "../assets/IMG_20210110_212238_853.jpg";

import cake_thumbnail from "../assets/cake_tn.jpg";
import cake2_thumbnail from "../assets/IMG_20200902_200201_306_tn.jpg";
import cake3_thumbnail from "../assets/IMG_20201012_234137_413_tn.jpg";
import cake4_thumbnail from "../assets/cake4_tn.jpg";
import cake5_thumbnail from "../assets/IMG_20201031_121833_003_tn.jpg";
import cake6_thumbnail from "../assets/IMG_20201119_185336_936_tn.jpg";
import cake7_thumbnail from "../assets/IMG_20200717_214513_784_tn.jpg"
import cake8_thumbnail from "../assets/IMG_20201128_155426_398_tn.jpg";
import cake9_thumbnail from "../assets/IMG_20210110_212238_853_tn.jpg";

import '../styles/cakegallery.css';
const Gallery = require('react-grid-gallery');

interface CakeGalleryProps { }
interface CakeGalleryState { }

const IMAGES =
    [{
        src: cake,
        thumbnail: cake_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    {
        src: cake2,
        thumbnail: cake2_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    {
        src: cake3,
        thumbnail: cake3_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    {
        src: cake4,
        thumbnail: cake4_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    {
        src: cake5,
        thumbnail: cake5_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    {
        src: cake6,
        thumbnail: cake6_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    
    {
        src: cake7,
        thumbnail: cake7_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    {
        src: cake8,
        thumbnail: cake8_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    },
    {
        src: cake9,
        thumbnail: cake9_thumbnail,
        thumbnailWidth: 375,
        thumbnailHeight: 500,
    }
    ]

class CakeGallery extends React.Component<CakeGalleryProps, CakeGalleryState> {
    render() {
        return (<div className="galleryWrapper">
            <Gallery images={IMAGES} margin={20} rowHeight={500} backdropClosesModal={true} />
        </div>
        )
    }
}

export default CakeGallery;
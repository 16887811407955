import * as React from "react";
import "../styles/ordering.css";

interface OrderingProps { }
interface OrderingState { }

class Ordering extends React.Component<OrderingProps, OrderingState> {
    render() {
        return (
            <div className="ordering">
                <h1 className="title">Tilaaminen</h1>
                <div className="ordering-content">
                    <div className="ordering-text">
                        <p>
                            Ota yhteyttä tilataksesi tuotteita ensisijaisesti sähköpostitse mielellään reilusti ennen toivottua ajankohtaa. Jos kuitenkin iskee pikainen tarve jollekin tuotteelle, kysy rohkeasti tilauksen mahdollisuutta! Voit valita tilattavan tuotteen valikoimastani tai voimme yhdessä suunnitella mieleisesi vaihtoehdon. Voit kysellä muidenkin tuotteiden saatavuutta, vaikket sitä tuotevalikoimastani löytäisikään.
                    </p>
                        <br />
                        <p>
                            Pakkaan tilatut tuotteet aina kuljetusta varten, ja tuotteet tulee noutaa kotileipomosta.
                    </p>
                        <br />
                        <p>
                            Tuotteiden maksaminen tapahtuu laskulla, jonka lähetän tilaajan sähköpostiin. Tuotteiden hinnat sisältävät alvin 14%.
                    </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Ordering;
